import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../index.css"
import "./FeaturedCards.css"
import Container from "react-bootstrap/Container";
import CardsDisplay from "../components/CardsDisplay";
import moment from 'moment';

export default class UpcomingEvents extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      upcoming_events: []
    }
  }

  componentDidMount() {
    let events = []
    fetch ("/api/upcoming_events")
      .then(response => response.json())
        .then(data => {
          events = data['upcoming_events'].map(event =>
            ({
              title: event.name,
              secondLine: moment(event.date).format("MM/DD/YY h:mm A") + " - " + moment(event.end_date).format("h:mm A"),
              description: event.description,
              link: event.link
            })
          );
        this.setState({
          upcoming_events: events
        });
      });
  }

  header() {
    return (
      <div className="cards-container">
        <Row className="cards-header">
          <Col sm={6}>
            <h3>Upcoming Events</h3>
          </Col>
          <Col sm={6}>
            <a className="view-all-button" href="/events/">
              View all events
            </a>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    return (
      this.state.upcoming_events && !!this.state.upcoming_events.length && <Container className="upcoming-events">
      {this.header()}
      <CardsDisplay items={this.state.upcoming_events}/>
    </Container>
    );
  }
}

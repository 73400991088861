import React from "react";
import {Col, Image} from "react-bootstrap";

const PartnerCard = ({item, handleShowPopup}) => {

    return (
      <div className="card-in-scroll">
      <Col md={3}>
            <a onClick={() => handleShowPopup(item)}>
                <Image src={item.logo} className="logo-pic cf-img">
                </Image>
            </a>
      </Col>
    </div>
    );
}
export default PartnerCard;

import './Events.css';
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Row, Col, Container, Tab, Nav } from "react-bootstrap";
import CalendarMonthView from "react-calendar-month-view";

import LinkButton from '../components/LinkButton/LinkButton'

export default function Events() {
    const subscriptionLink = "https://calendar.google.com/calendar/r?cid=yourenext.ca_8h00n8e97qi3n98oo1nvlhat3k@group.calendar.google.com";
    return (
        <>
            <h1>Events</h1>
            <EventsBox />
            {/* <SubscribeSection subscriptionLink={subscriptionLink} /> */}
        </>
    )
}

const now1 = moment();

const EventsBox = () => {
    const [events, setEvents] = useState({});
    const [currentDay, setCurrentDay] = useState(+moment().startOf('day'));
    const now = moment().subtract(1, 'days');

    const handleDayClick = (event) => {
        setCurrentDay(Number(event.currentTarget.id));
    };

    useEffect(() => {
        fetch("/api/events")
            .then(response => response.json())
            .then(data => {

                setEvents(data['events'].reduce((dict, event) =>
                ({
                    ...dict,
                    [+moment(event.date).startOf('day')]: {
                        name: event.name,
                        description: event.description,
                        link: event.link,
                        date: moment(event.date),
                        endDate: moment(event.end_date),
                        location: event.location

                    }
                }), {})
                );
            });
    }, []);


    const renderDay = day => {
        const date = moment(day).startOf('day');
        return (
            <div onClick={handleDayClick} id={date} className="day" style={currentDay === +date ? { backgroundColor: "rgba(255, 196, 57, 0.2)" } : {}}>
                <p>{date.format("DD")}</p>
                {
                    +date in events ?
                        [
                            <b>{events[+date].name.slice(0, 24)}...</b>, // sliced so it kinda looks nice
                            <p>{events[+date].date.format("h:mmA")} - {events[+date].endDate.format("h:mmA")}</p>
                        ]
                        : null
                }

            </div>
        );
    };

    const event = events[currentDay];

    return (
        <Container>
            <Row className="tabs-box">
                <Tab.Container defaultActiveKey="Calendar">
                    <div>
                        <Nav class="nav nav-justified flex-column flex-md-row">
                            <Nav.Item>
                                <Nav.Link eventKey="Calendar">
                                    Calendar
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="List">
                                    List
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    {/* <hr class ="sliding-line"></hr> */}
                    <hr class="base-line"></hr>
                    <Row>
                        <Tab.Content>
                            <Tab.Pane eventKey="Calendar">
                                <div className="events-tab-content">
                                    <Calendar renderDay={renderDay} event={event} />
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="List">
                                <div className="events-tab-content">
                                    <Container className="box-container">
                                        {events ? Object.values(events).filter(event => event.date.isAfter(now))
                                            .sort((a, b) => +a.date - +b.date)
                                            .map(event => <EventRow event={event} />) : 
                                            <h1>No Upcoming Events</h1>
                                        }
                                    </Container>
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Row>
                </Tab.Container>
            </Row>
        </Container>
    );
}

const Calendar = ({ renderDay, event }) => {
    return (
        <div className="box-container">
            <Row>
                <Col sm={12} md={9} className="cal-col">
                    <div className="cal-month-view-container">
                        <CalendarMonthView
                            renderDay={renderDay}
                            width="100%"
                            inactiveDayStyle={{ backgroundColor: "white" }}
                            activeDayStyle={{ backgroundColor: "white" }}
                            titleTextStyle={{ color: "#4D4D4D", fontSize: "24px", fontWeight: "bold" }}
                            dayNameTextStyle={{ color: "#5B5B5C", fontWeight: "bold", textAlign: "center" }}
                            dayTextStyle={{ display: "none" }}
                        />
                    </div>
                </Col>
                <Col sm={12} md={3} className="cal-side-panel">
                    {
                        event ?
                            [
                                <h5>{event.date.format("MMM DD").toUpperCase()}</h5>,
                                <p>{event.date.format("h:mm A")} - {event.endDate.format("h:mm A")} | {event.location}</p>,
                                <h3>{event.name}</h3>,
                                <p>{event.description}</p>
                            ]
                            : <h3>No events on this day.</h3>
                    }
                    {event && event.link !== '/events' && !event.date.isBefore(now1) && <div className="center" ><LinkButton text="Register" href={event.link} /></div>}
                </Col>
            </Row>
        </div>
    )
}

const EventRow = ({ event }) => {
    return (
        <div className="event-row">
            <Row>
                <Col sm={7}>
                    <h4>{event.name}</h4>
                    <p>{event.description}</p>
                </Col>
                <Col sm={5} className="event-location">
                    <p className="fas fa-calendar-alt"></p><span> {event.date.format("MMMM DD, YYYY")}</span>
                    <br />
                    <p className="far fa-clock"></p><span> {event.date.format("h:mm A")} - {event.endDate.format("h:mm A")}</span>
                    <br />
                    <p className="fas fa-map-marker-alt"></p><span> {event.location || "TBD"}</span>
                    {event && event.link !== '/events' && <div className="center"><LinkButton text="Register" href={event.link} /></div>}
                </Col>
            </Row>
        </div>
    );
}

const SubscribeSection = ({ subscriptionLink }) => {
    return (
        <section className="subscribe-section">
            <h3>Don't want to miss an event?</h3>
            <p>Let's stay in touch! Export our calendar to stay updated with our events</p>
            <LinkButton text="Subscribe to YNCN Calendar" href={subscriptionLink} />
        </section>
    )
}
import React from "react";
import Row from "react-bootstrap/Row";
import "./../index.css"
import PartnerCard from "./PartnerCard"
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { LeftArrow, RightArrow } from "./PartnerCardArrow";

function onWheel(apiObj, ev){
  const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

  if (isThouchpad) {
    ev.stopPropagation();
    return;
  }

  if (ev.deltaY < 0) {
    apiObj.scrollNext();
  } else if (ev.deltaY > 0) {
    apiObj.scrollPrev();
  }
}


const PartnerCardDisplay = ({items, handleShowPopup}) => {
    const listOfCards = items.map((item,index) => {
      return (
      <PartnerCard
        item= {item}
        key={index}
        itemId={index}
				handleShowPopup={handleShowPopup}
      />)
      });

    return (
      <Row className="box-container">
        <ScrollMenu
          onWheel={onWheel}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="card-in-scroll"
          scrollContainerClassName="horizontal-slider"
          >
            {listOfCards}
          </ScrollMenu>
      </Row>
    );
}

export default PartnerCardDisplay;

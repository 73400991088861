import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { NavDropdown } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';

import './Resources.css';
import CardItem from "../components/CardItem";

export default function Resources (props) {
	const resourceTypes = ["articles", "podcasts"];
	const history = useHistory();

	let initialResourceType = props.location.pathname.split("/")[2]?.toLowerCase();
	if (!resourceTypes.includes(initialResourceType)) {
		initialResourceType = resourceTypes[0];
		history.push("/resources/" + initialResourceType);
	}
	initialResourceType = initialResourceType.charAt(0).toUpperCase() + initialResourceType.slice(1);

  const tags = ["Career Fair", "Hiring Process", "Personal Discovery", "Work Advice", "Industry Knowledge"];

  const sortButtons = ["A - Z", "Z - A", "New - Old", "Old - New", "Featured"] //  Sort Button Options Array

  const [activeTags, setActiveTags] = useState([]);
  const [resources, setResources] = useState([]);
	const [currentResources, setCurrentResources] = useState([]);
  const [activeSort, setActiveSort] = useState("Featured");
	const [resourceType, setResourceType] = useState(initialResourceType);

  useEffect(() => {
      fetch("/api/resources")
          .then(response => response.json())
          .then(data => {
								setResources(data['resources'].map(res =>
              ({
                title: res.name,
                secondLine: res.tags,
                description: res.description,
                date: res.date,
                link: res.link,
                tags: res.tags,
								type: res.type
              })
								));
						setCurrentResources(data['resources'].map(res =>
              ({
                title: res.name,
                secondLine: res.tags,
                description: res.description,
                date: res.date,
                link: res.link,
                tags: res.tags,
								type: res.type,
                featured: res.featured
              })).filter(res => res.type === resourceType.toLowerCase()));
          });
  }, []);
	
	useEffect(() => {
		return history.listen((location) => {
			const newResourceType = location.pathname.split("/")[2]?.toLowerCase();
			setResourceType(newResourceType.charAt(0).toUpperCase() + newResourceType.slice(1));
		});
	}, [history])

	useEffect(() => {
		setCurrentResources(resources.filter(res => res.type === resourceType.toLowerCase()))
	}, [resourceType])

	const handleChangeResourceType = (e) => {
		history.push("/resources/" + e.target.innerText.toLowerCase());
		setResourceType(e.target.innerText);
	}

  const handleFilterButtonClick = tagClicked => {
    if (activeTags.includes(tagClicked)) {
      setActiveTags(activeTags.filter(tag => tag !== tagClicked));
    } else {
      setActiveTags([...activeTags, tagClicked]);
    }
  }

  const handleDropDownButtonClick = sortClicked => {
    setActiveSort(sortClicked);
  }

  const sortResources = () => {
    let resourceCopy = currentResources;

    const types = {
      "A - Z": "titleAsc",
      "Z - A": "titleDesc",
      "Old - New": "dateAsc",
      "New - Old": "dateDesc",
      "Featured": "featured"
    };

    const sortProperty = types[activeSort];

    if (sortProperty === "titleAsc") {
      resourceCopy.sort((a, b) => (a.title > b.title) ? 1: -1);
    }
    else if (sortProperty === "titleDesc") {
      resourceCopy.sort((a, b) => (b.title < a.title) ? -1: 1);
    }
    else if (sortProperty === "dateAsc") {
      resourceCopy.sort((a, b) => (a.date > b.date) ? 1: -1);
    }
    else if (sortProperty === "dateDesc") {
      resourceCopy.sort((a, b) => (b.date < a.date) ? -1: 1);
    } else if (sortProperty === "featured") {
      resourceCopy.sort((a, b) => (b.date < a.date) ? -1: 1);
      resourceCopy.sort((a, b) => a.featured ? -1 : 0);
    }
    return resourceCopy;
  }

  return (
      <div className="resources">
          <TopTextSection resourceType={resourceType} handleChangeResourceType={handleChangeResourceType}/>

          <FilterSection tags={tags} activeTags={activeTags} clearFilter={() => setActiveTags([])} onClick={handleFilterButtonClick}/>
          <DropDownButton className="right-space" sortButtons={sortButtons} activeSort={activeSort} onClick={handleDropDownButtonClick}/>

          {sortResources()
              .filter(resource => activeTags.length === 0 || resource.tags.filter(tag => activeTags.includes(tag)).length > 0)
              .map(resource => <CardItem item={resource} />)}
      </div>
  );
}


const TopTextSection = ({resourceType, handleChangeResourceType}) => {
    return (
        <section className="resources-header">

					<div className="top-header-container">
						<div>
							<h3 style={{paddingRight: 14, marginBottom: 0}}>YNCN</h3>
						</div>
						<div>
							<h3 style={{color: "#313B47 !important"}}>
								<NavDropdown title={resourceType} id="dropdown-text" className="resource-dropdown">
									<NavDropdown.Item className="resource-type-choice" onClick={handleChangeResourceType}>Articles</NavDropdown.Item>
									<NavDropdown.Item className="resource-type-choice" onClick={handleChangeResourceType}>Podcasts</NavDropdown.Item>
								</NavDropdown>
							</h3>
						</div>
					</div>
					<h1>How can we help?</h1>
					<h4>Choose categories you are interested in:</h4>
			</section>
    );
}


const FilterSection = ({tags, activeTags, clearFilter, onClick}) => {
  return (
      <section>
          {tags.map(tag => <FilterButton tag={tag} tagCSS={tag.replaceAll(" ", "-")} onClick={onClick} activeTags={activeTags}/>)}
          { activeTags.length > 0 &&
            <button className="clear-filter" onClick={clearFilter}>
              <svg className="multiply right-space"  width="25" height="25" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 1V15" stroke="black" stroke-width="2" stroke-linecap="round"/>
                  <path d="M15 8L1 8" stroke="black" stroke-width="2" stroke-linecap="round"/>
              </svg>
              Clear Filters
            </button>
          }
      </section>
  );
}


const FilterButton = ({tag, tagCSS, onClick, activeTags}) => {
  const handleButtonClick = () => {
    onClick(tag);
  }

  let active = activeTags.includes(tag);

  return (
    <button id={tagCSS.toLowerCase()} className="filter" onClick={handleButtonClick}>
      {tag}
      <svg className={ active ? "multiply" : null}  width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 1V15" stroke="white" stroke-width="2" stroke-linecap="round"/>
          <path d="M15 8L1 8" stroke="white" stroke-width="2" stroke-linecap="round"/>
      </svg>
    </button>
  );
}


// Sort component
const DropDownButton = ({sortButtons, activeSort, onClick}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle className="sort" variant="primary" id="dropdown-basic-button" size="lg">
      {activeSort}
      </Dropdown.Toggle>
      <Dropdown.Menu>
          {sortButtons.map(sortButton => <SortButton sortButton={sortButton} onClick={onClick}/>)}
      </Dropdown.Menu>
    </Dropdown>
  );
}


const SortButton = ({sortButton, onClick}) => {
  const handleButtonClick = () => {
    onClick(sortButton);
  }
  return (
      <Dropdown.Item id={sortButton} onClick={handleButtonClick }>
        {sortButton}
      </Dropdown.Item>
  );
}

import './Footer.css';
import { Container, Row } from "react-bootstrap";
import React, { Component } from 'react';

export default function Footer() {
    return (
        <footer id="footer">
            <Container fluid>
                <Row>
                    <div class="text-center" style={{padding:'2.5em'}}>
                        <FooterIcons/>
                        <FooterText/>
                    </div>
                </Row>
            </Container>
        </footer>
    );
}

// If Font Awesome icons are not working, check the Font Awesome DDN key in index.html file inside the head tags
const FooterIcons = () => {
    return (
        <div class="home-pg">
            <Row>
                <div class="social-media">
                    <div class="icons-wrapper">
                        <div class="icon">
                            <a href="https://www.facebook.com/UofTYoureNext?fref=ts" target="_blank" rel="noopener noreferrer">
                                <button href="">
                                    <span class="fa-stack">
                                        <i class="fab fa-facebook-square fa-stack-2x" style={{color: '#156177'}}></i>
                                    </span>
                                </button>
                            </a>
                        </div>
                        <div class="icon">
                            <a href="mailto:contact@yourenext.ca" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <span class="fa-stack">
                                        <i class="fas fa-envelope fa-stack-2x" style={{color: '#156177'}}></i>
                                    </span>
                                </button>
                            </a>
                        </div>
                        <div class="icon">
                            <a href="https://www.instagram.com/yncnuoft/" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <span class="fa-stack">
                                        <i class="fab  fa-instagram fa-stack-2x" style={{color: '#156177'}}></i>
                                    </span>
                                </button>
                            </a>
                        </div>
                        <div class="icon">
                            <a href="https://discord.gg/SmhXv7KYC4" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <span class="fa-stack">
                                        <i class="fab  fa-discord fa-stack-2x" style={{color: '#156177'}}></i>
                                    </span>
                                </button>
                            </a>
                        </div>
                        <div class="icon">
                            <a href="https://www.tiktok.com/@yncnuoft?lang=en" target="_blank" rel="noopener noreferrer">
                                <button>
                                    <span class="fa-stack">
                                        <i class="fab  fa-tiktok fa-stack-2x" style={{color: '#156177'}}></i>
                                    </span>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
}

const currentYear = new Date().getFullYear();
const FooterText = () => {
    return (
        <div class="footer-text">
            <a href="https://madmimi.com/signups/a64c937ab6c94b2797d1d464f9592437/join">
                <p><strong>Sign Up For Our Newsletter</strong></p>
            </a>
            <p>
                <small>&copy; {currentYear} You're Next Career Network</small>
            </p>
        </div>
    );
}
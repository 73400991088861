import React, {Component } from 'react';
import {Row, Col} from "react-bootstrap";
import './CFResources.css';

export default class CFResources extends Component {
      render() {
          return (
            <div className="cf-resources">
              <TopTextSection/>
              <div className="cf-resources-container">
                <Col>
                <Row>
                  <a className="cf-resource-button Y" href="https://yourenext.ca/cf/map">
                    Career Fair Venue Map
                  </a>
                  </Row>
                  <Row>
                  <a className="cf-resource-button N" href="https://forms.gle/XWW43sQoKLrtgy8C8">
                    Resume Book Dropoff
                  </a>
                  </Row>
                  <Row>
                  <a className="cf-resource-button C" href="https://yourenext.ca/partners">
                    Company List
                  </a>
                  </Row>
                  <Row>
                  <a className="cf-resource-button N-" href="https://www.instagram.com/yncnuoft/">
                    Instagram
                  </a>
                  </Row>
                </Col>
              </div>
            </div>
          );
      }
}

const TopTextSection = () => {
  return (
      <section className="maps-header">
          <h1>YNCN Career Fair Resources</h1>
      </section>
  );
}
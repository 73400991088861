import React from "react";
import Row from "react-bootstrap/Row";
import "./../index.css"
import CardItem from "./CardItem"
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import {LeftArrow, RightArrow} from "../partners/PartnerCardArrow";
import "./CardsDisplay.css"

const display = ({items}) => {
  const listOfCards = items.map((item, index) => {
    return (
    <CardItem
      item= {item}
      key={index}
      itemId={index}
    />)
  });

  function onWheel(apiObj, ev){
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;
  
    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }
  
    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  }

  const renderScrollMenu = () => {
    if (items.length !== 0) {
      return (
        <ScrollMenu
          onWheel={onWheel}
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          itemClassName="card-in-scroll"
          scrollContainerClassName="horizontal-slider"
          >
            {listOfCards}
        </ScrollMenu>
      )
    }
  }

  return (
    <Row className="box-container">
      { renderScrollMenu() }
    </Row>
  );
}

export default display;

import React from "react";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import "./../index.css"

const card = ({item}) => {
    const secondLineColor = (label) => {
      console.log(label);
      console.log(label[0])
      switch(label[0]) {
        case "Career Fair": 
          return "rgb(238, 110, 91)";
        case "Hiring Process":
          return "rgb(238, 183, 79)";
        case "Personal Discovery":
          return "rgb(62, 158, 163)";
        case "Work Advice":
          return "rgb(72, 137, 102)";
        case "Industry Knowledge":
          return "rgb(45, 96, 117)";
        default:
      }
    }
    return (
      <div className="card-in-scroll">
      <Col className="card-column" md={3}>
        <Card className="card-item">
            <h4>{item.title}</h4>
            <h5 style={{ color: `${secondLineColor(item.secondLine)}`}}>{item.secondLine}</h5>
            <p>{item.description}</p>
            <a href={item.link} className="stretched-link"> </a>
        </Card>
      </Col>
    </div>
    );
  }

  export default card;

import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./../index.css"
import "./FeaturedCards.css"
import Container from "react-bootstrap/Container";
import CardsDisplay from "../components/CardsDisplay";

export default class FeaturedResources extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      resources: []
    }
  }

  componentDidMount() {
    let resources = []
    fetch ("/api/featured_resources")
      .then(response => response.json())
        .then(data => {
          resources = data['featured_resources'].map(res =>
            ({
              title: res.name,
              secondLine: res.tags,
              description: res.description,
              link: res.link
            })
          );
        this.setState({
          resources: resources
        });
      });
  }

  header() {
    return (
      <div className="cards-container">
        <Row className="cards-header">
          <Col sm={6}>
            <h3>Featured Resources </h3>
            <p>Curated for Students by Students</p>
          </Col>
          <Col sm={6}>
            <a className="view-all-button" href="/resources/">
              View all Resources
            </a>
          </Col>
        </Row>
      </div>
    );
  }


  render() {
    return (
      <Container className="featured-resources">
        {this.header()}
        <CardsDisplay items={this.state.resources}/>
      </Container>
    );
  }
}

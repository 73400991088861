import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";

export default function CompaniesFilter({companies, setFilteredCompanies}) {
  const filterYears = [
    { filterName: "1st Year", id: 1 },
    { filterName: "2nd Year", id: 2 },
    { filterName: "3rd Year", id: 3 },
    { filterName: "4th Year/New Grad", id: 4 },
    { filterName: "PEY", id: 5 },
  ];

  const filterDisciplines = [
    { filterName: "ECE", id: 1 },
    { filterName: "INDY", id: 2 },
    { filterName: "ENGSCI", id: 3 },
    { filterName: "MECH", id: 4 },
    { filterName: "CIV", id: 5 },
    { filterName: "CHEM", id: 6 },
    { filterName: "MIN", id: 7 },
    { filterName: "MSE", id: 8 },
    { filterName: "COMMERCE", id: 9 },
    { filterName: "ARTSCI", id: 10 },
    { filterName: "CS", id: 11 },
    { filterName: "OTHER", id: 12 },
  ];

  const [yearOptions] = useState(filterYears);
  const [disciplineOptions] = useState(filterDisciplines);

  const [yearFilter, setYearFilter] = useState([]);
  const [disciplineFilter, setDisciplineFilter] = useState([]);
  const [textFilter, setTextFilter] = useState("");

  const textFilterOnChange = (event) => {
    setTextFilter(event.target?.value);
  };

	useEffect(() => {
    setFilteredCompanies(companies.filter(
			(company) =>
				company.name &&
				company.name.toLowerCase().includes(textFilter.toLowerCase())
				&& (yearFilter.length === 0 || yearFilter.some(year => company.targetYears.includes(year)))
				&& (disciplineFilter.length === 0 || disciplineFilter.some(discipline => company.targetPrograms.includes(discipline)))
		))
	}, [yearFilter, disciplineFilter, textFilter])

  const handleYearFilterChange = (selectedList) => {
		const tempFilter = [];
		for (let i = 0; i < selectedList.length; i++) {
			tempFilter.push(selectedList[i].filterName)	
		}
		setYearFilter(tempFilter)
  };

  const handleDisciplineFilterChange = (selectedList) => {
		const tempFilter = [];
    const otherFilters = ["MARKETING", "MATH", "PHYSICS", "AEROSPACE", "INDUSTRY", "COMMUNICATIONS", "STATISTICS", "ARCHITECTURE"];
		for (let i = 0; i < selectedList.length; i++) {
      if (selectedList[i].filterName === "OTHER") {
        for (let j = 0; j < otherFilters.length; j++) {
          tempFilter.push(otherFilters[j]);	
        }
      } else {
        tempFilter.push(selectedList[i].filterName)	
      }
		}
		setDisciplineFilter(tempFilter)
  };

  return (
    <div className="filter-header-bg">
      <div className="filter-wrapper">
        <Row>
          <Col className="search-filter" xs={12} sm={6} md={3}>
            <input
              placeholder="Search company name or keyword..."
              value={textFilter}
              onChange={textFilterOnChange}
            />
          </Col>

          <Col className="years-filter" xs={12} sm={6} md={3}>
            <Multiselect
              options={yearOptions}
              placeholder="Select Year(s)"
              onSelect={handleYearFilterChange}
							onRemove={handleYearFilterChange}
              displayValue="filterName"
              avoidHighlightFirstOption="true"
            />
          </Col>

          <Col className="disciplines-filter" xs={12} sm={6} md={3}>
            <Multiselect
              options={disciplineOptions}
              placeholder="Select Discipline(s)"
							onSelect={handleDisciplineFilterChange}
							onRemove={handleDisciplineFilterChange}
              displayValue="filterName"
              avoidHighlightFirstOption="true"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

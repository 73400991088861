import bluePersonImg from '../assets/images/about/blue-person.png'
import redPersonImg from '../assets/images/about/red-person.png'
import greenPersonImg from '../assets/images/about/green-person.png'
import thanksForComingImg from '../assets/images/about/thanks-for-coming.png'
import './About.css';
import React from 'react';


import { Container, Row, Col } from "react-bootstrap";

export default function About() {
    return (
        <div>
            <WhoWeAreSection/>
            <div className="about-orange">
                <OurVisionSection/>
                <OurStrategySection/>
            </div>
            <ThanksForComingSection/>
        </div>
    );
}

const WhoWeAreSection = () => {
    return (
        <div className="about-header">
            <h1 className="pg-title">Who We Are</h1>
            <p className="about-subtext">
                You’re Next Career Network is a multi-disciplinary team of
                over 50 student volunteers. Our goal is to provide opportunities to students through
                career development programs, as well as corporate and startup career opportunities.
                Working with over 6000 students and 100+ companies a year, in addition to supporting
                other U of T engineering clubs by providing $15K a year, we aim to continue bringing
                exciting opportunities to the students of U of T.
            </p>
        </div>
    );
}

const OurVisionSection = () => {
    return (
        <div className="our-vision">
            <h1>Our Vision</h1>
            <p>To empower University of Toronto’s student body to discover available career paths, prepare for next steps, and provide opportunities to connect and apply.</p>
        </div>
    );
}


const OurStrategySection = () => {

    return (
        <div>
            <h1 className="our-strategy">Our Strategy</h1>

            <Container fluid>
                <Row>
                    <Col xs="12" sm="4">

                        <Row>
                            <Col sm={{span:10, offset:1}}>
                                <img src={bluePersonImg} alt=""></img>
                                <h3>Discover</h3>
                                <p>Our events and expert speaker panels allow students to explore different industries and career pathways</p>
                            </Col>
                        </Row>

                    </Col>
                    <Col xs="12" sm="4">

                        <Row>
                            <Col sm={{span:10, offset:1}}>
                                <img src={redPersonImg} alt=""></img>
                                <h3>Prepare</h3>
                                <p>We provide students with professional development resources through content and workshops</p>
                            </Col>
                        </Row>

                    </Col>

                    <Col xs="12" sm="4">

                        <Row>
                            <Col sm={{span:10, offset:1}}>
                                <img src={greenPersonImg} alt=""></img>
                                <h3>Apply</h3>
                                <p>Our Fall and Winter Career Fairs and recruitment events offers opportunities for students to network and develop professional experience</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>

            </Container>
        </div>
        );
}

const ThanksForComingSection = () => {
    return (
        <div className="goodbye">
            <img src={thanksForComingImg} alt=""></img>
            <p>Thanks for coming!</p>
        </div>
    );
}
import React, { useState } from 'react';
import "./SideMenu.css"

const Collapsible = ({company}) => {
    const [open, setOPen] = useState(false);

    const toggle = () => {
        setOPen(!open);
    };

    return (
      <div>
        <button onClick={toggle} className="toggle-btn">Click For Company Description...</button>
        {open && (
            <div className="toggle-cf">{company.description}</div>)}
      </div>
    );
};
export default Collapsible;
import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Tab from "react-bootstrap/Tab";
import "./MeetOurTeam.css";
import "../about/About.css";
import { Nav } from "react-bootstrap";

export default class MeetOurTeam extends React.Component {
    constructor(props) {
        super(props);
        this.peoplePerRow = 4;
    }
    componentDidMount() {
        let teams = {};
        fetch("/api/team")
            .then(response => response.json())
            .then(data => {
                data.team.forEach(member => {
                    if (!(member.team in teams)) {
                        teams[member.team] = [];
                    }
                    teams[member.team].push(member);
                });
                this.setState(
                    {
                        leadershipMembersGrid: this.createTeamMembers(
                            teams.Leadership
                        ),
                        dlpMembersGrid: this.createTeamMembers(teams.DLP),
                        bdMembersGrid: this.createTeamMembers(teams.BD),
                        opsMembersGrid: this.createTeamMembers(teams.Ops),
                        bgmMembersGrid: this.createTeamMembers(teams.BGM),
                        dsMembersGrid: this.createTeamMembers(teams.DS),
                    }
                );
            });
    }

    getTeamDescription(teamName) {
        switch (teamName) {
            case "Leadership":
                return (
                    <div className="team-description">
                        <h2>The Leadership Team</h2>
                        <p>
                        We are leaders to over 50 students who are passionate about professional development. 
                        We aim to build an environment where students drive one another to take control of their 
                        careers and grow their skills to be the best versions of themselves. This year, we are 
                        looking forward to growing our industry and community partners to continue furthering 
                        YNCN’s mission.
                        </p>
                    </div>
                );
            case "Ops":
                return (
                    <div className="team-description">
                        <h2>The Operations & Logistics Team</h2>
                        <p>
                            Operations and Logistics is a team of 10 dedicated to
                            the planning and execution of events
                            that help students excel on their career development journey.
                            We partner with companies and experienced alum to host
                            events such as student development workshops, industry info sessions,
                            interview preps, and resume workshops.
                            We are always looking for ways to provide better opportunities for students,
                            so drop us a message if you have any ideas about
                            what events you want to see us run next!
                        </p>
                    </div>
                );
            case "BD":
                return (
                    <div className="team-description">
                        <h2>The Business Development Team</h2>
                        <p>
                            Business Development is responsible for forming and fostering strategic
                            partnerships with top companies to meet the needs of our diverse network of
                            over 6000 students. We also work closely with these partners to host events,
                            like our career fairs, to provide students with opportunities to network with
                            recruiters as well as develop skills to help them reach their career goals.
                        </p>
                    </div>
                );
            case "DLP":
                return (
                    <div className="team-description">
                        <h2>The Digital Launchpad Team</h2>
                        <p>
                            Welcome to Digital Launchpad!
                            We are the techies of YNCN,
                            here to lead the digital transformation of the 
                            organization from the inside out. 
                        </p>
                        <p>
                            Our focus this year is to enhance YNCN’s digital
                            offerings and automate internal/external
                            processes with innovative technical solutions.
                            We embrace the Agile methodology and support
                            the growth of our team members.
                            Most importantly, we work hard to provide the
                            best possible experience for the students!
                        </p>
                    </div>
                );
            case "BGM":
                return (
                    <div className="team-description">
                        <h2>The Brand Growth & Marketing Team</h2>
                        <p>
                            The Brand Growth & Marketing Team (BGM) is the artistic side of YNCN! We’re the creative minds behind our
                            articles, graphics, animation, podcasts, videography, photography and TikTok!
                            Without us every piece of content would be
                            size 12 Times New Roman, double spaced, single-sided. We are also the team
                            responsible for spreading the YNCN word to the people and engaging with the community on our social media platforms!
                        </p>
                    </div>
                );
            case "DS":
                return (
                    <div className="team-description">
                        <h2>The Data Strategy Team</h2>
                        <p>
                            The Data Strategy team (DS) is home to the data analysts of YNCN. By harnessing the power of data, 
                            we provide a strong foundation for data-driven decision-making and optimization within YNCN!
                        </p>
                        <p>
                            We’re also the team that is focused on understanding all
                            the challenges faced, the needs, and the wants of the students at UofT.
                            We’re helping the YNCN team make informed decisions to provide the best
                            support to UofT students in the career development process!
                        </p>
                    </div>
                )
            default:
                break;
        }
    }

    tabs() {
        return (
            <Container>
                <div className="about-pg">
                    <h1>Meet Our Team</h1>
                    <Row className="tabs-box">
                        <Tab.Container defaultActiveKey="Leadership">
                            <div > 
                                <Nav class="nav nav-justified flex-column flex-md-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey="Leadership">
                                            Leadership
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Ops">
                                            Operations & Logistics
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="BD">
                                            Business Development
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="DLP">
                                            Digital Launchpad
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="BGM">
                                            Brand Growth & Marketing
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="DS">
                                            Data Strategy
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            {/* <hr class ="sliding-line"></hr> */}
                            <hr class = "base-line"></hr>
                            <Row>
                                <Tab.Content>
                                    <Tab.Pane eventKey="Leadership">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription(
                                                "Leadership"
                                            )}
                                            {this.state.leadershipMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="Ops">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription(
                                                "Ops"
                                            )}
                                            {this.state.opsMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="BD">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription("BD")}
                                            {this.state.bdMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="DLP">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription("DLP")}
                                            {this.state.dlpMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="BGM">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription("BGM")}
                                            {this.state.bgmMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="DS">
                                        <div className="team-tab-content">
                                            {this.getTeamDescription("DS")}
                                            {this.state.dsMembersGrid}
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Row>
                        </Tab.Container>
                    </Row>
                </div>
            </Container>
        );
    }

    createTeamMembers(team) {
        let rows = [];

        let colWidth = Math.floor(12 / this.peoplePerRow);
        let rowClassName = (team.length < this.peoplePerRow) ? "justify-content-center": "justify-content-left";
        
        if (team.length === 2 && team[1].position.startsWith("President")){ //ordering for leadership team
            let copy = team[0]
            team[0] = team[1]
            team[1] = copy
        } else { //ordering for all other teams
            let count = 0;
            for (let i=0; i<team.length; i++){
                if (team[i].position.includes("Director")){
                    let copy = team[i]
                    team[i] = team[count]
                    team[count] = copy
                    count++
                }
            }
        }

        for (var i = 0, j = team.length; i < j; i += this.peoplePerRow) {
            let rowMembers = team.slice(i, i + this.peoplePerRow);

            let cols = rowMembers.map(member => {
                return (
                    <Col
                        key={member.name}
                        lg={colWidth}
                        md={6}
                        className="profile"
                    >
                        <a
                            href={member.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <Image
                                src={member.image}
                                alt={member.name}
                                className="profile-pic d-block mx-auto"
                            ></Image>
                        </a>
                        <div className="about-team-desc">
                            <div className="about-team-text">
                                <strong>{member.name}</strong>
                                <p>{member.position}</p>
                            </div>
                        </div>
                    </Col>
                );
            });
            let row = <Row key={`row ${i}`} className={rowClassName}>{cols}</Row>;
            rows.push(row);
        }
        return rows;
    }

    render() {
        if (this.state != null) {
            return this.tabs();
        } else {
            return <Container></Container>;
        }
    }
}

import React from 'react'

function ScrollToTop({ className = '' }) {

  function handleClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
      <button className={className} onClick={handleClick}>Back to Top</button>
  )
}

export default ScrollToTop
import React from 'react'
import './BoothOverlay.css'

const BoothOverlay = ({item: {left, top, width, height, boothid, deg}, handleShowPopup, scale, partner}) => {
	return (
		<div text-align="center" id="booth-overlay" 
			style={{
				left: scale * left + 'px', 
				top: scale * top + 'px', 
				width: scale * width + 'px', 
				height: scale * height + 'px',
				transform: `rotate(${-deg}deg)`,
				transformOrigin: 'center',
			}} onTouchEnd={(e) => {handleShowPopup(e, partner)}} onClick={(e) => {handleShowPopup(e, partner)}}
		>
			<span position="absolute" style={{transform: `rotate(${deg}deg)`}}>
				{boothid}
			</span>
		</div>
	)
}

export default BoothOverlay
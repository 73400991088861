import React, { useState } from "react";
import "../components/LogoDisplay.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./PopupCard.css";
import moment from "moment";

const PopupCard = ({ show, handleClose, partner }) => {
  const [showMoreDesc, setShowMoreDesc] = useState(false);

  const listItems = partner.career_fairs?.map((item) => (
    <button
      className="cf"
      style={{ backgroundColor: item === "Fall" ? "#ff4500" : "#0F52BA" }}
    >
      {item}
    </button>
  ));

  const listEvents = partner.events?.map((event) => (
    <div className="events-list"
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr 0.5fr 2fr 1fr",
        gridGap: 20,
      }}
    >
      <div>{moment.utc(event?.['date']).format('MM/DD/YYYY')}</div>
      <div>{moment.utc(event?.['date']).format('hh:mm A')}</div>
      <div>{event?.['name']}</div>
      <div>
        <a href={event?.['registration_link']}>Register &gt;</a>
      </div>
    </div>
  ));

  return (
    <>
      <Modal
        className="partner-popup"
        size="lg"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>          
          <Row className="justify-content-center logo-link">
            <Col xs="auto">
              <a
                className="logo-link"
                href={partner.link}
                target="_blank"
                rel="noreferrer"
              >
                <img className="cf-img gold-logo" src={partner.logo} alt="" />
              </a>
            </Col>
          </Row>

          <h1 className="partner-name-text">{partner.name}</h1>
          <h3 className="industry-text">{partner.industry}</h3>
          <p className="partner-description-text">
            {showMoreDesc ? partner?.description : partner?.description?.slice(0, 150)}
						{partner?.description &&
            <button className="show-more-btn" onClick={() => setShowMoreDesc(!showMoreDesc)}>
							<div className="dots">{showMoreDesc ? '▲' : '∙∙∙'}</div>
            </button>
						}
          </p>
          
          <div className="career-fairs">
          <h3 className="career-fairs-header">{partner.tier == "Event" ? "" : "Career Fairs"}</h3>
            <div>{listItems}</div>
          </div>
          
          {partner?.events?.length ?
          <div>
            <div className="events">
              <h3 className="events-header">Events</h3>
              <br></br>
            </div>
            <div>{listEvents}</div>
          </div>
          : null}
        </Modal.Body>

      </Modal>
    </>
  );
};

export default PopupCard;

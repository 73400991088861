import React, { useState } from 'react';
import { Row, Col, Image, Container } from "react-bootstrap";
import Collapsible from "./Collapsible";

import './SideMenu.css';

export default function SideMenu({booths}) {

    const filteredCompanies = booths.filter(company => company.name)
    const sortedBooths = filteredCompanies.sort((booth, nextBooth) => booth.boothid - nextBooth.boothid)
    const shortBooths = sortedBooths.map(booth => {
        booth.targetPrograms = booth.targetPrograms.map(program => {
            if (program == "Computer Science") program = "CS"
            else if (program == "Civil Engineering") program = "CIV"
            else if (program == "Chemical Engineering") program = "CHEM"
            else if (program == "Mechanical Engineering") program = "MECH"
            else if (program == "Electrical & Computer Engineering") program = "ECE"
            else if (program == "Engineering Science") program = "ENGSCI"
            else if (program == "Industrial Engineering") program = "INDY"
            else if (program == "Mineral Engineering") program = "MIN"
            else if (program == "Material Engineering") program = "MSE"
            else if (program == "Arts & Science") program = "ARTSCI"
            else if (program == "Commerce & Business") program = "COMMERCE"
            else if (program == "Architecture") program = "ARCH"
            else program = program.toUpperCase()
            return program
        })
        return booth
    })

    return (
        <>
            <div className="side-menu-row-container">
                {shortBooths.map(company => <CompanyRow company={company} key={company.name} />)}
            </div>
        </>
    );
}

const CompanyRow = ({company}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="company-row">
            <Container style={{ marginBlock: 0}}>
                <Row className="row">
                    <Col sm={2}>
                        <Image src={company.logo} fluid className="cf-map-image"/>
                    </Col>
                    <Col sm={8}>
                        <Row xs lg="2">
                            <a className="company-name" href={company.link} style={{ paddingBottom: 0 }}><span className="cf-highlight-green">Booth {company.boothid} - <span className="cf-company-name">{company.name}</span><span className={company.tier}>{company.tier}</span></span></a>
                        </Row>
                        <Row>
                            <Col sm className="target-info" style={{ margin: '5px 0'}}>
                                <b>HIRING: </b>
                                <span className="highlight">{company.targetPrograms.join(", ")}</span>
                            </Col>
                            <Col sm className="target-info">
                                <b>YEAR: </b>
                                <span className="highlight-gray">{company.targetYears.join(", ")}</span>
                            </Col>
                        </Row>
                        <Collapsible company={company}>
                            <Row>
                                <p className={expanded ? "" : "minimized-text"}>{company.description}</p>
                                {!expanded && <p className="expand-button" onClick={() => {setExpanded(true)}}>Read More</p>}
                            </Row>
                        </Collapsible>
                    </Col>
                </Row>
            </Container>
        </div>
    );
  }
import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import "./Employers.css";
import pastCommPartners from "../assets/images/about/employers_past_comm_partners.jpg";
import pastCorpPartners from "../assets/images/about/employers_past_corp_partners.jpg";
import pastStpPartners from "../assets/images/about/employers_past_stp_partners.jpg";
import eventsCF from "../assets/images/about/employers_events_CF.jpg";
import eventsSCF from "../assets/images/about/employers_events_SCF.jpg";
import eventsSpotLight from "../assets/images/about/employers_events_spotlight.jpg";
import reachLoc from "../assets/images/about/employers_reach_loc.jpg";
import reachPwr from "../assets/images/about/employers_reach_pwr.jpg";
import reachRss from "../assets/images/about/employers_reach_rss.jpg";
import LinkButton from "../components/LinkButton/LinkButton";
import React, { Component } from 'react';


export default function Employers() {
    return (
        <div className="content">
            <Container fluid></Container>
            <Container>
                <h1 className="text-center employers-page-title">Employers</h1>
                {tabsSection()}
                {benefitsSection()}
                {contactCard()}
            </Container>
        </div>
    );
}

function getYearsSinceEst() {
    let date1 = new Date(2009, 9, 1);
    let date2 = new Date();
    let yearsDiff =  date2.getFullYear() - date1.getFullYear();
    return yearsDiff;
}

const benefitsSection = () => {
    return (
        <div className="home-pg">
            <div className="benefits">
                <Row>
                    <Col sm={6}>
                        <h3> We have history</h3>
                        <p>
                            YNCN is the largest professional development
                            organization at the University of Toronto. We’ve been around for {getYearsSinceEst()} years and reach over 6000 students (and counting!)
                            every year.
                        </p>
                    </Col>
                    <Col sm={6}>
                        <img class="employers-content-img" src={pastCorpPartners} alt="model of Android Mascot posing"></img>
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 6, order: 'last' }}>
                        <h3> We innovate</h3>
                        <p>
                            Each year YNCN works hard to bring new kinds of events to the
                            table. From Career Fairs to networking events and intimate
                            workshops, there’s an event for everyone - students and employers
                            alike.
                        </p>
                    </Col>
                    <Col sm={6}>
                        <img class="employers-content-img" src={pastStpPartners} alt="2 Career Fair Attendees speaking to each other"></img>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <h3> We are diverse</h3>
                        <p>
                            The You’re Next Career Network caters not only to a diverse body
                            of engineering students, but also a significant group of computer
                            science and business students. Located in the heart of Toronto, we
                            also get students from other universities in the GTA attending our
                            hugely successful Career Fairs.
                        </p>
                    </Col>
                    <Col sm={6}>
                        <img class="employers-content-img" src={pastCommPartners} alt="staffed MaRS booth speaking to person"></img>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

const contactCard = () => {
    return (
        <Row className="convinced-yet">
            <Row>
                <h3>Convinced yet?</h3>
                <p>
                    Even if you aren’t, send us a quick note and we’ll gladly talk to
                    you about it.{" "}
                </p>
            </Row>
            <div className="contact-us-box">
                <a className="white-black-button" href="/contact">
                    Contact Us
                </a>
            </div>
        </Row>
    )

}

const tabsSection = () => {
    return (
        <div className="tabs">
            <Tab.Container defaultActiveKey="careerfairs">
                <Nav fill variant="tabs">
                    <Nav.Item>
                        <Nav.Link eventKey="careerfairs">Fall & Winter Career Fairs</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="events">Co-Creation Events</Nav.Link>
                    </Nav.Item>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="careerfairs">
                        {careerFairsTab()}
                    </Tab.Pane>
                    <Tab.Pane eventKey="events">
                        {coCreationEventsTab()}
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

const careerFairsTab = () => {
    return (
        <div className="youre-next-tab">
            <h3>Fall & Winter Career Fairs</h3>
            <p>You’re Next Career Fairs are held biannually and attract top students
                from all faculties at the University of Toronto. With 100+ companies and
                6000 attendees expected every year, the You’re Next Career Fairs will undoubtedly
                enhance your campus presence, expand recruitment opportunities, and connect you to the right talent.</p>
            <Row className="image-container">
                <Col sm={6} md={4} className="image">
                    <img src={eventsCF} alt="" />
                </Col>
                <Col sm={6} md={4} className="image">
                    <img src={eventsSCF} alt="" />
                </Col>
                <Col sm={6} md={4} className="image">
                    <img src={eventsSpotLight} alt="" />
                </Col>
            </Row>
            <div style={{ 'text-align': 'center' }}>
                <h3> Interested in Attending? </h3>
                <p>If your company may be interested in attending the upcoming, please reach out as soon as possible! {/*Winter Career Fair on February 3rd, 2023, please reach out to us prior to January 16th, 2023!*/}</p>
                <br></br>
                <LinkButton href="/contact" text="Contact Us" />
            </div>
        </div>
    )
}

const coCreationEventsTab = () => {
    return (
        <div className="youre-next-tab">
            <h3>Co-Creation Events</h3>
            <p>Limited to only our top companies, Co-Creation events are customized experiences that allow for the most in-depth interactions with our students. The You’re Next team will work closely with you to build a unique event that goes beyond a typical information session and attracts the best talent that suits your company.</p>
            <Row className="image-container">
                <Col sm={6} md={4} className="image">
                    <img src={reachRss} alt="" />
                </Col>
                <Col sm={6} md={4} className="image">
                    <img src={reachLoc} alt="" />
                </Col>
                <Col sm={6} md={4} className="image">
                    <img src={reachPwr} alt="" />
                </Col>
                <section className="company-package">
                    <Row>
                        <Col sm={6}>
                            <h3> See our list of Upcoming Events! </h3>
                            <p>See what we're up to, and get inspiration for your own events.</p>
                        </Col>
                        <Col sm={6}>
                            <LinkButton href="/events/calendar" text="Upcoming Events" />
                        </Col>
                    </Row>
                </section>
            </Row>
        </div>
    )
}

import "./../index.css";
import "./Partners.css";
import "./StandardPartners.css";
import { Container, Row } from "react-bootstrap";
import PartnerCardDisplay from "./PartnerCardDisplay";
import React from 'react';


function header() {
  return (
    <div className="cards-container">
      <Row className="cards-header">
        <h3>Standard Career Fair Partners</h3>
      </Row>
    </div>
  );
}

export default function StandardPartners(props) {
  return (
    <Container className="standard-partners">
      {header()}
      <PartnerCardDisplay
        items={props.partners}
        handleShowPopup={props.handleShowPopup}
      />
    </Container>
  );
}

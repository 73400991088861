import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import LinkButton from "../components/LinkButton/LinkButton";
import konradLogo from "../assets/images/summit/konrad_logo.png";
import perpetuaLogo from "../assets/images/summit/perpetua_logo.jpg";
import qualcommLogo from "../assets/images/summit/qualcomm_logo.jpg";
import recruitIllustration from "../assets/images/summit/recruit-illustration.png";
import summitGraphic from "../assets/images/summit/summit-web.jpg";
import React, { Component } from 'react';

import "./Summit.css";

export default function CFSummit() {
  return (
    <div>
      <Image src={summitGraphic} fluid></Image>
      <Container>
        <div className="career-fair-content career-fair-kickoff">
          {generalInfo()}
          {keynotes()}
          {contact()}
        </div>
      </Container>
    </div>
  );
}

function generalInfo() {
  return (
    <Row className="info-row gx-5">
      <Col md={4} xs={12}>
        <div className="details-box">
          <h3>Details</h3>
          <div className="details-text">
            <p>
              <i className="fa fa-map-marker-alt" />
              Jan 14, 6:30 PM - 9:00 PM
            </p>
            <p>
              <i className="fa fa-clock" />
              Online - Hopin
            </p>
            <p>
              <i>
                You do not need an additional ticket for Summit, if you already
                have one for Winter Career Fair.
              </i>
            </p>
          </div>
          <div className="details-buttons">
            <LinkButton
              href={"https://hopin.com/events/yncn-winter-career-fair"}
              text="Register"
              className="details-button"
            ></LinkButton>
          </div>
        </div>
      </Col>
      <Col xs={12} md={8}>
        <div className="about-cf-box">
          <h3>About the Summit</h3>
          <p>
            Ditch the resume and come network with company recruiters at the
            Summit -- an evening of keynote speeches and scintillating
            conversation!
          </p>
          <br />
          <p>
            Join us on Thursday, January 14th from 6:30PM to 9:00PM at Hopin for
            a laid back night of networking with representatives from companies
            that will be recruiting at our Winter Career Fair!{" "}
          </p>
          <br />
          <p>
            <b>The Summit </b> <i>is just the pre-game.</i>
            <br />
            Be sure to follow up with a resume at the Winter Career Fair on
            Friday, January 15th!
            <a href="https://www.facebook.com/events/224935485645686/">
              Check the Winter Career Fair event out on Facebook
            </a>
          </p>
          <LinkButton
            href="/cf/about"
            text="Go to Winter Career Fair"
          ></LinkButton>
        </div>
      </Col>
    </Row>
  );
}

function keynotes() {
  return (
    <Row className="info-row">
      <div className="keynote-speaker">
        <Row>
          <Col sm={12} md={6}>
            <div className="image-wrapper">
              <Image src={konradLogo}></Image>
            </div>
            <div className="description">
              <p className="highlight">This year's Keynote</p>
              <h3>Konrad Group</h3>
              <p>
                Konrad Group is a next generation digital consultancy. We are
                dedicated to solving complex business problems for our global
                clients with creative and forward-thinking solutions. Our
                employees enjoy a culture built on innovation and a commitment to
                creating best-in-class digital products in use by hundreds of
                millions of consumers around the world. We hire exceptionally
                smart, analytical, and hard working people who are lifelong
                learners.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="image-wrapper">
              <Image src={qualcommLogo}></Image>
            </div>
            <div className="description">
              <p className="highlight">This year's Keynote</p>
              <h3> Qualcomm Canada ULC </h3>
              <p>
                At Qualcomm, students and college graduates jump right into
                next-generation technologies that are changing the wireless world.
                In a world where power and capabilities keep increasing to compete
                with other leading companies in the industry, Qualcomm knocks out
                its competition in almost every category. Engineering
                opportunities in the Toronto area: Electrical Engineering,
                Computer Engineering and Computer Science AI/Machine Learning,
                Software & Asic Design Engineering for mobile, compute, audio,
                automotive and IOT platforms.
              </p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={{span:6, offset: 3}}>
            <div className="image-wrapper">
              <Image src={perpetuaLogo}></Image>
            </div>
            <div className="description">
              <p className="highlight">This year's Keynote</p>
              <h3>Perpetua</h3>
              <p>
                Perpetua provides growth optimization and reporting technology for
                the world’s smartest ecommerce businesses. Through the platform,
                advertisers create goals based on strategy and rely on Perpetua’s
                best in class, AI-powered optimization engine to execute
                tactically. Integrations with Amazon, Instacart and Google ensure
                brands achieve full-funnel engagement and maximum visibility.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </Row>
  );
}

function contact() {
  return (
    <Row className="info-row">
      <Col sm={12} lg={6}>
        <Image src={recruitIllustration} fluid></Image>
      </Col>
      <Col sm={12} lg={6}>
        <h3>Want to Attend?</h3>
        <p>
          If you’re a student, register now. If you want to attend as a company
          next year, shoot us an email!
        </p>
          <LinkButton
            href="https://hopin.com/events/yncn-winter-career-fair"
            text="Register"
          ></LinkButton>
          <LinkButton href="/contact" text="Get In Touch"></LinkButton>
      </Col>
    </Row>
  );
}

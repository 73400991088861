import React, { useState } from "react";
import "../components/LogoDisplay.css";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "./PopupCardMap.css";
import moment from "moment";



const PopupCard = ({ show, handleClose, partner}) => {
    
  const [showMoreDesc, setShowMoreDesc] = useState(false);

  const listPrograms = partner.targetPrograms?.map(program => {
    if (program == "Computer Science") program = "CS"
    else if (program == "Civil Engineering") program = "CIV"
    else if (program == "Chemical Engineering") program = "CHEM"
    else if (program == "Mechanical Engineering") program = "MECH"
    else if (program == "Electrical & Computer Engineering") program = "ECE"
    else if (program == "Engineering Science") program = "ENGSCI"
    else if (program == "Industrial Engineering") program = "INDY"
    else if (program == "Mineral Engineering") program = "MIN"
    else if (program == "Material Engineering") program = "MSE"
    else if (program == "Arts & Science") program = "ARTSCI"
    else if (program == "Architecture") program = "ARCH"
    else program = program.toUpperCase()
    return program
  })

  const listEvents = partner.events?.map((event) => (
    <div className="events-list"
      style={{
        display: "grid",
        gridTemplateColumns: "0.5fr 0.5fr 2fr 1fr",
        gridGap: 20,
      }}
    >
      <div>{moment.utc(event?.['date']).format('MM/DD/YYYY')}</div>
      <div>{moment.utc(event?.['date']).format('hh:mm A')}</div>
      <div>{event?.['name']}</div>
      <div>
        <a href={event?.['registration_link']}>Register &gt;</a>
      </div>
    </div>
  ));

  return (
    <>
      <Modal
        className="partner-popup"
        size="lg"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>          
          <Row className="justify-content-center logo-link">
            <Col xs="auto">
              <a
                className="logo-link"
                href={partner.link}
                target="_blank"
                rel="noreferrer"
              >
                <img className="cf-img gold-logo" src={partner.logo} alt="" />
              </a>
            </Col>
          </Row>

         <div className="name-tier-container"><h1 className="partner-name-text">{partner.name}</h1><span className={partner.tier}>{partner.tier}</span></div>
          <h3 className="industry-text">{partner.industry}</h3>
          <p className="partner-description-text">
            {showMoreDesc ? partner?.description : partner?.description?.slice(0, 150)}
						{partner?.description &&
            <button className="show-more-btn" onClick={() => setShowMoreDesc(!showMoreDesc)}>
							<div className="dots">{showMoreDesc ? '▲' : '∙∙∙'}</div>
            </button>
						}
          </p>
          
          
            <div className="career-fairs-header">
                
                      <div style={{ marginLeft: 'auto',    marginRight: 'auto',    marginTop: '0',     display: 'inline-block', width: '50%' }}>
                      <h3 className="career-fairs-header">HIRING:</h3>
                      <span className="highlight">{listPrograms?.join(", ")}</span>
                </div>
             
                      
            
                
                <div style={{ marginLeft: 'auto',    marginRight: 'auto',    marginTop: '0',     display: 'inline-block', width: '50%' }}>

                      <h3 className="career-fairs-header">YEAR:</h3>
                      <span className="highlight-gray">{partner.targetYears?.join(", ")}</span>
                </div>
            </div>    
            

          
        </Modal.Body>

      </Modal>
    </>
  );
};

export default PopupCard;
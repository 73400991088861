import React from 'react';

import Navbar from './components/Navbar'
import Footer from './components/Footer'
import CarouselComponent from './components/Carousel';


import Home from './home/Home'
import About from './about/About'
import Employers from './employers/Employers';
import Contact from './contact/Contact'
import Events from './events/Events'
import Partners from './partners/Partners'
import Resources from './resources/Resources'
import CFMap from './careerfair/Map'
import CFResources from './careerfair/CFResources'
import MeetOurTeam from './team/MeetOurTeam'

import CFAbout from './careerfair/About'
import CFCompanies from './careerfair/Companies'
import CFSummit from './careerfair/Summit'

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";


export default function App() {
    return (
        <Router>
            <Navbar />

            {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/about" component={About} />
                <Route path="/employers" component={Employers} />
                <Route path="/events" component={Events} />
                <Route path="/resources" component={Resources} />
                <Route path="/contact" component={Contact} />
                <Route path="/partners" component={Partners} />
                <Route path="/team" component={MeetOurTeam} />

                <Route path="/cf/about" component={CFAbout} />
                <Route path="/cf/summit" component={CFSummit} />
                <Route path="/cf/companies" component={CFCompanies} />
                <Route path="/cf/resources" component={CFResources} />

                <Route exact path="/" component={Home} />

                <Route component={NotFound} />

            </Switch>

            <Footer />


        </Router>
    );
}



function NotFound() {
    return <h2>Not Found!!! :OOO</h2>;
}

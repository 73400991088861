import LogoItem from "./LogoItem"
import Row from "react-bootstrap/Row"
import "./LogoDisplay.css";
import React from 'react';


const LogoGrid = ({logos, tier, handleShowPopup}) => {
    const listOfLogos = logos.map((logo) => {
      return (
        <LogoItem
          item= {logo}
          tier= {tier}
          handleShowPopup={handleShowPopup}
        />)
      });
    return (
      <Row className="cf-list justify-content-center" id="cf-scroll">
        {listOfLogos}                 
      </Row>
    );
}
export default LogoGrid;
import React, {Component } from 'react';
import { Row, Col } from "react-bootstrap";
import { MapInteraction } from 'react-map-interaction';
import FloorPlan from '../assets/images/careerfair/TheCarluMapDLP.jpg'
import './Map.css';
import PopupCard from '../components/PopupCardMap';

import BoothOverlay from '../components/Map/BoothOverlay.js'

import SideMenu from '../components/Map/SideMenu'
import CompaniesFilter from '../components/Map/CompaniesFilter';
import ScrollToTop from '../components/Map/ScrollToTop';

import { throttle } from "lodash";

const imgDimension = {
  x: 1275, 
  y: 1650
}


export default class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
          // Pixel coords of booth pins relative to the map element. Pixel coords differ between
          // different browsers, devices, browser sizes, etc. Don't worry too much about this.
          coords: {x: 0, y: 0},
          lastClick: {x: 0, y: 0},
          // Array to hold all the booth elements.
          cf_booths: [],
					cf_booth_overlays: [],

					filteredCompanies: [],

          clickedPartner: {},
          showPopUp: false,
          value: {
            scale: 1,
            translation: { x: 0, y: 0 }
          },
          imgBoundingBox: {
            xMin: 50,
            xMax: 50,
            yMin: 50,
            yMax: 50
          }
        }
        this.mapRef = React.createRef();
        this.mapContainerRef = React.createRef();
        this.translations = {}
      }

      componentDidMount() {
        let booths = [];
        fetch("/api/cf_map")
        .then(response => response.json())
        .then(data => {
          data.cf_map.forEach(booth => {
            booths.push(
              {
                boothid: booth.boothid,
                name: booth.name[0],
                description: booth.description[0],
                logo: booth.logo,
                targetYears: booth.target_years,
                targetPrograms: booth.target_programs,
                link: booth.link[0],
                career_fairs: booth.career_fairs,
                tier: booth.tier,
                width: booth.width,
                height: booth.height,
                left: booth.left_x_coordinate,
                top: booth.top_y_coordinate,
								deg: booth.rotation,
                width: booth.width,
                height: booth.height,
              }
            )
          }
          );
          this.setState({cf_booths: booths, cf_booth_overlays: booths, filteredCompanies: booths});
        });



      }

			setFilteredCompanies = (filteredCompanies) => {
				this.setState({filteredCompanies: filteredCompanies}, () => console.log(this.state.filteredCompanies))
			}

			handleTransformBooth = () => {
				return this.mapRef?.current?.offsetWidth / imgDimension.x;
			}

      handleShowPopup = (event, partner) => {
        console.log(partner);
        if (event.defaultPrevented) return;
        this.setState({clickedPartner: partner});
        this.setState({showPopUp: true});
      }
      
      handleClosePopup = () => this.setState({showPopUp: false});
      
      calculateCoords(clientX, clientY) {
        const { left, top, width, height } = this.mapRef.current.getBoundingClientRect()

        const x = (clientX - left) * imgDimension.x / width
        const y = (clientY - top) * imgDimension.y / height

        return { x, y }
      }

      recalcBoundingBox(translation) {
        const mapContainer = this.mapContainerRef.current
        const floorplan = this.mapRef.current

        if (!mapContainer || !floorplan) return

        const { width: containerWidth, height: containerHeight, bottom } = mapContainer.getBoundingClientRect()
        const { width: imgWidth, height: imgHeight } = floorplan.getBoundingClientRect()

        const vh = Math.max(document.documentElement.clientHeight || 0, 0)

        const overflowedY = bottom - vh;

        const xMin = -1 * Math.abs(imgWidth -  containerWidth + 50)
        const xMax = Math.abs(imgWidth - containerWidth - 50)
        const yMin = -1 * Math.abs(imgHeight - containerHeight + 50 + (overflowedY > 0 ? Math.abs(overflowedY) : 0))

        const translateX = Math.min(Math.max(translation.x, xMin), xMax)
        const translateY = Math.min(Math.max(translation.y, yMin), 100)
        this.translations = {
          x: translateX,
          y: translateY
        }
      }

      render() {
          return (
            <div className="maps">
                <ScrollToTop className='scrollTopButton'></ScrollToTop>
                <TopTextSection/>

                {/* EVERYTHING UNDER THIS COMMENT IS THE MAP 
                    TO DO: WE SHOULD PUT THE MAP INTO A SEPARATE FUNCTIONAL/CLASS COMPONENT OR SOMETHING. */}

                <CompaniesFilter companies={this.state.cf_booth_overlays} setFilteredCompanies={this.setFilteredCompanies}/>

                {/* COMMENT: Render all the added booth pins. */}
    
                {/* COMMENT: subtract 15 and 40 because we want to align the tip of the pin with the cursor. 
                    TO DO: THIS IS JANKY RIGHT NOW, WE SHOULD PROBABLY MAKE THESE CONSTS INTO A VAR IF WE
                    WANT TO MAKE THE SIZE OF THE PINS RESPONSIVE (we should do it). */}
                <Row className="cf-map-row">
                  <Col className="col1">
                    <div className="map" onMouseMove={(e) => this.setState({ coords: this.calculateCoords(e.clientX, e.clientY) }) }>
                      <div className="mapContainer" ref={this.mapContainerRef}>
                        <MapInteraction>
                        {
                          ({ translation, scale }) => {
                            // Translate first and then scale.  Otherwise, the scale would affect the translation.
                            this.recalcBoundingBox(translation)
                            scale = scale < 0.7 ? 0.7 : scale
                            const transform = `translate(${this.translations.x}px, ${this.translations.y}px) scale(${scale})`;
                            return (
                              <div
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  position: 'relative',
                                  overflow: 'hidden',
                                  touchAction: 'none',
                                  msTouchAction: 'none',
                                  cursor: 'all-scroll',
                                  WebkitUserSelect: 'none',
                                  MozUserSelect: 'none',
                                  msUserSelect: 'none'
                                }}
                              >
                                <div style={{ display: 'inline-block', transform: transform, transformOrigin: '0 0 '}}>
                                  <img id="floorplan" 
                                    className="cf-map-img" 
                                    src={FloorPlan} 
                                    ref={this.mapRef}>
                                  </img>
                                  {this.state.filteredCompanies.map((boothInfo) => {return (<BoothOverlay item={boothInfo} handleShowPopup={this.handleShowPopup} partner={boothInfo} scale={this.handleTransformBooth()} />)})}
                                </div>
                              </div>
                            );
                          }
                        }
                        </MapInteraction>
                      </div>
                    </div>
                  </Col>
                  <Col className="col2">
                    <PopupCard show={this.state.showPopUp} handleClose={this.handleClosePopup} partner={this.state.clickedPartner} />
                    <div className="companies">
                      <SideMenu booths={this.state.filteredCompanies}/>
                    </div>
                  </Col>
                </Row>
            </div>
          );
      }
}

const TopTextSection = () => {
    return (
        <section className="maps-header">
            <h1>Fall Career Fair Map</h1>
            <h4>An interactive map to help you navigate the career fair and find which companies are hiring students like YOU!</h4>
        </section>
    );
}
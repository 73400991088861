import './Contact.css';
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import React from 'react';

export default function Contact() {
    return (
        <div className="contact-bg">
            <GetInTouchSection />
            <FormSection />
        </div>
    );
}

const GetInTouchSection = () => {
    return (
        <section className="left-side">
            <h1>Get In Touch</h1>
            <p>If you're interested in partnering with us or have general questions, please don't hesitate to send us a message. {/*For employers, the deadline to register for our upcoming Winter Career Fair is January 16th, 2023.*/}</p>
            <p>For more immediate answers, you can chat with us through our <a href="https://www.instagram.com/yncnuoft/">Instagram</a> page.</p>
            <Container className="contact-details">
                <Row>
                    <Col sm={12} className="info">
                        <h3>Email</h3>
                        <p>president@yourenext.ca</p>
                        <h3>Location</h3>
                        <p>B740 10 King's College Road University of Toronto <br/>Toronto, ON M5S3G4</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

class FormSection extends React.Component {
    constructor() {
        super()
        this.state = {
            userId: 1,
            name: null,
            email: null,
            subject: null,
            text: null
        }
    }

    handleClick(id) {
        this.setState({userId: id});
    }

    handleUpdate = (e, fieldName) => {
        this.setState({[fieldName]: e.target.value});
    }

    handleSubmit = e => {
        e.preventDefault();
        const request = {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "mode": "cors"
            },
            body: JSON.stringify({
                "subject": this.state.subject,
                "message": this.state.text,
                "email": this.state.email,
                "name": this.state.name
            })
        };
        fetch('/api/contact', request)
          .then(response => alert("Success: Your message is received."))
          .catch(error => alert("Failed to send out the message. Please reach out to the YNCN Digital Team."));
    }

    render() {
        let fieldText = this.state.userId === 1 ? "Subject" : "Company"

        return (
            <section className="right-side">
                <Form className="form" onSubmit={this.handleSubmit}>
                    <Row className="format-text">
                        <Col align="center">
                            <Button className={this.state.userId === 1? "header teal" : "header"} onClick={() => this.handleClick(1)}>Students</Button>
                        </Col>
                        <Col align="center">
                            <Button className={this.state.userId === 2? "header teal" : "header"} onClick={() => this.handleClick(2)}>Employers</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control required type="name" placeholder="Name" className="field-formatting" onChange={(e)=>this.handleUpdate(e, "name")}/>
                            <Form.Control required type="email" placeholder="Email" className="field-formatting" onChange={(e)=>this.handleUpdate(e, "email")}/>
                            <Form.Control required type="subject" placeholder={fieldText} className="field-formatting" onChange={(e)=>this.handleUpdate(e, "subject")}/>
                            <Form.Control required as="textarea" rows={3} placeholder="Message" className="field-formatting" onChange={(e)=>this.handleUpdate(e, "text")}/>
                            <Button type="submit" className="button">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </section>
        );
    }
}
